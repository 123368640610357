import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoPlayer from './components/VideoPlayer';

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* Route to display the video player by video ID */}
                    <Route path="/:id" Component={VideoPlayer} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;